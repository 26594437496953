<template>
    <vx-card title="Commission Record"> </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'RecordCard',
    mixins: [textFormat],
    props: [],
    components: {},
    data() {
        return {
            show: {},
        }
    },
    computed: {},
    methods: {},
}
</script>
