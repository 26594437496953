<template>
    <div id="page-user-view">
        <div class="vx-row">
            <div class="vx-col w-full mb-3">
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <RecordCard></RecordCard>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <CommissionListCard></CommissionListCard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
]

<script>
import { http } from '@/services'
import RecordCard from './Cards/Record'
import CommissionListCard from './Cards/CommissionList'

export default {
    name: 'UserView',
    components: {
        CommissionListCard,
        RecordCard,
    },
    created() {},
    beforeRouteUpdate(to, from, next) {
        next()
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            backPath: null,
            show: {},
        }
    },
}
</script>
